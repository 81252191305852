@import "@liveblocks/react-comments/styles.css";
@import "@liveblocks/react-comments/styles/dark/attributes.css";

html {
  font-size: 75%;
}

/* Liveblocks comments overrides */

.lb-root {
  --lb-background: transparent;
  --lb-elevation-background: #fff;
}

[data-theme="dark"] {
  --lb-background: transparent;
  --lb-elevation-background: #222;
}

svg > path {
  /* Fix for completed checkmark after removing --lb-background */
  --lb-icon-background: #fff;
}

[data-theme="dark"] svg > path {
  /* Fix for completed checkmark after removing --lb-background */
  --lb-icon-background: #222;
}

.lb-comment,
.lb-composer-editor,
.lb-composer-footer {
  /* Remove horizontal padding from comments since the sidebar is pre-padded */
  padding-left: 0;
  padding-right: 0;
}

.lb-comment:not(:first-child) {
  /* Indent threaded comments */
  padding-inline-start: calc(var(--lb-spacing) + var(--lb-comment-avatar-size));
}
